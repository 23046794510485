import React from "react"
import { Link } from "gatsby"
import TriangleIcon from "./TriangleIcon"

import {
  button,
  buttonColored,
  buttonUnderline,
  icon,
} from "../styles/button.module.css"

const InnerButton = ({ children, colored, underline }) => (
  <button
    className={`
				${button} ${colored ? buttonColored : ""}
				${underline ? buttonUnderline : ""}
				`}
  >
    {children}
    <span className={icon}>
      <TriangleIcon />
    </span>
  </button>
)

export default function Button({ to, children, colored, underline }) {
  const link = to ?? "/" //default to home
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <Link to={link}>
        <InnerButton colored={colored} underline={underline}>
          {children}
        </InnerButton>
      </Link>
    )
  }
  return (
    <a href={link} target="_blank" rel="noreferrer">
      <InnerButton colored={colored} underline={underline}>
        {children}
      </InnerButton>
    </a>
  )
}
