import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import LinkButton from "../components/LinkButton"

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {
  heroWrapper,
  heroCopy,
  article,
  articleWrapper,
  quoteWrapper,
  quoteImage,
  quote,
} from "../styles/home.module.css"

export default function Home({ data }) {
  const art = data.allFile.nodes[0]
  const hero = data.allFile.nodes[1]
  const contentfulHero = data.allContentfulHomeHeroImage?.nodes[0]?.heroImage

  const artImage = getImage(art)
  const heroImage = contentfulHero ? getImage(contentfulHero) : getImage(hero)

  return (
    <Layout>
      <section>
        <section className={`${heroWrapper} container`}>
          <GatsbyImage image={heroImage} alt="Hero image" />
        </section>

        <section className={`${articleWrapper} container`}>
          <article className={article}>
            <h3>Photo</h3>
            <p>
              <Link to="/photo">Explore</Link> a captivating collection of
              pictures, ranging from original and exclusive photography artwork
              to commissioned assignments.
            </p>
          </article>

          <article className={article}>
            <h3>Art — Design</h3>
            <p>
              <Link to="/art-design">Have a look</Link> at the studio’s unique
              work and commissioned assignments.
            </p>
          </article>

          <article className={article}>
            <h3>News</h3>
            <p>
              <Link to="/news">Check out</Link> the highlights and take in all
              the latest updates on personal work, exciting collaborations, and
              fresh creative produce.
            </p>
          </article>
        </section>

        <section className={quoteWrapper}>
          <GatsbyImage className={quoteImage} image={artImage} alt="Card art" />
          <blockquote className={`${quote} container`}>
            <p>
              Studio KATHAN is all about sparking ideas of incorporating various
              beautiful entanglements of shapes, lights, lines and carriers. A
              deep fondness for art and a keen eye for detail make sure that
              taking the time to do research on materials and productions
              becomes a vital part of the creative process. This is in harmony
              with the studio’s most important values: pureness, authenticity
              and sustainability in the most multidisciplinary sense.
            </p>
            <LinkButton colored underline to="/about">
              Read more about the studio
            </LinkButton>
          </blockquote>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    allFile(
      filter: { relativeDirectory: { eq: "static" }, name: { regex: "/home/" } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData
        }
        name
      }
    }
    allContentfulHomeHeroImage(sort: { fields: date, order: DESC }) {
      nodes {
        heroImage {
          gatsbyImageData
        }
        title
      }
    }
  }
`
